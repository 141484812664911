import {NavComponent} from '../../components/navbar/nav.component';
import {CustomSelect} from '../../components/custom-select/custom-select';
import {ProxyProductCard} from '../../components/proxy-product-card/proxy-product-card';
import {AppConstants} from '../../shared/app.constants';
import {MainPageAccount} from './main-page-account';
import {SelectChanger} from '../../components/select-changer/select-changer';
import {MainPageConstants} from './main-page.constants';
import Swiper, {Navigation, Pagination} from 'swiper';
import {Jivoloader} from '../../components/jivosite/jivoloader';

export class MainPage {
    private swiper: Swiper;
    private swiperSelector: string;
    private swiperConfig: any;

    constructor(
        private navComponent: NavComponent,
        private customSelect: CustomSelect,
        private proxyProductCard: ProxyProductCard,
        private proxyAccountTs: MainPageAccount,
        private selectChanger: SelectChanger,
        private jivoLoader: Jivoloader
    ) {
    }

    public init(): void {
        this.swiperSelector = '.swiper-container';
        this.swiperConfig = MainPageConstants.SWIPER_CONFIG;
        // uncomment for comments :)
        // this.createSwiperInstance();
        this.navComponent.init();
        this.customSelect.init();
        this.proxyProductCard.init();
        this.proxyAccountTs.init();
        this.jivoLoader.init();

        this.resetSelectsEventSubscription();
    }

    private resetSelectsEventSubscription(): void {
        const eventName = AppConstants.RESET_SELECTS_LISTENERS_EVENT_NAME;

        document.addEventListener(eventName, (event) => {
            this.customSelect.resetEventSubscriptions();
        });
    }

    private createSwiperInstance(): void {
        Swiper.use([Navigation, Pagination]);
        this.swiper = new Swiper(this.swiperSelector, this.swiperConfig);
        this.swiper.update();
    }
}
