import {VpnPriceParams} from '../../interfaces/vpn-price-params.interface';

export class VpnProductCardService {
    public getPrice(params: VpnPriceParams): number {
        let cost = 0;
        const isHidden = params.privateIpElement.classList.contains('hidden');
        if (!isHidden) {
            cost = params.selectedCityPrice.reduce((prev, cur, index, arr) => {
                prev += params.selectedCityPrice[index] * (params.selectedCount[index] || 0) * params.periodMonthsCount;
                return prev;
            }, 0);
        }
        cost = cost + params.originalPrice;

        if (params.discount > 1) {
            const percent = params.originalPrice / 100;
            cost -= percent * params.discount;
        }
        cost = parseFloat(cost.toFixed(1));
        return cost;
    }

    public checkPromocode(url: string, rawRequestParams: string[][]): Promise<any> {
        return fetch(`${url}?${new URLSearchParams(rawRequestParams).toString()}`);

    }

    public parsePromocodeErrors(response: any, promocode: string): string {
        if (!response) {
            return 'Вы ввели неверный промокод!';
        }
        switch (true) {
            case response.status === 'noStart':
                return 'Промокод не действителен!';
            case response.status === 'expired':
                return 'Время действия промокода истекло!';
            case response.status === 'countError':
                return `Промокод не действителен для заказанного кол-ва!`;
            case !!promocode:
                return 'Вы ввели неверный промокод!';
            default:
                return null;
        }
    }
}
