import {NavComponent} from '../../components/navbar/nav.component';
import {Jivoloader} from '../../components/jivosite/jivoloader';
import {CustomSelect} from '../../components/custom-select/custom-select';
import {MainPage} from './main-page';
import {ProxyProductCard} from '../../components/proxy-product-card/proxy-product-card';
import {ProxyProductCardService} from '../../components/proxy-product-card/proxy-product-card.service';
import {AppService} from '../../shared/app.service';
import {VpnProductCardService} from '../../components/vpn-product-card/vpn-product-card.service';
import {ProxyPacketBaseService} from '../proxy-packet-base/proxy-packet-base.service';
import {MainPageAccount} from './main-page-account';
import {SelectChanger} from '../../components/select-changer/select-changer';

{
    const proxy = new MainPage(
        new NavComponent(),
        new CustomSelect(),
        new ProxyProductCard(
            new ProxyProductCardService()
        ),
        new MainPageAccount(new AppService(), new VpnProductCardService(), new ProxyPacketBaseService()),
        new SelectChanger(),
        new Jivoloader()
    );
    proxy.init();
}
