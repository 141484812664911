import {CustomSelectStatic} from './custom-select-static';
import {CustomSelectConstants} from './custom-select.constants';
import {Helpers} from '../../shared/helpers';
import * as LazyLoad from 'vanilla-lazyload/dist/lazyload.js';

export class CustomSelect extends CustomSelectStatic {
    private customSelectSelector: string;
    private customSelectDropdownSelector: string;
    private selectClickCallbackRef: any;
    private selectItemsClickCallbackRef: (event: Event) => void;

    public init(): void {
        this.setInitialValues();
        this.eventsSubscription();
        this.imagesLazyLoadInit();
    }

    public resetEventSubscriptions(): void {
        this.eventsSubscription();
    }

    private imagesLazyLoadInit(): void {
        const lazyLoadInstance = new LazyLoad({
            // Your custom settings go here
        });
        lazyLoadInstance.update();
    }

    private eventsSubscription(): void {
        this.selectClickSubscription();
        this.selectDropdownItemsClickSubscription();
    }

    private selectClickSubscription(): void {
        const selectElements = document.querySelectorAll(this.customSelectSelector);
        Helpers.removeListener(selectElements, this.selectClickCallbackRef, 'click');
        this.selectClickCallbackRef = CustomSelect.getSelectClickCallback;

        selectElements.forEach(element => element.addEventListener('click', this.selectClickCallbackRef));
    }


    private selectDropdownItemsClickSubscription(): void {
        const selectItemsElements = document.querySelectorAll(this.customSelectDropdownSelector);
        Helpers.removeListener(selectItemsElements, this.selectItemsClickCallbackRef, 'click');
        this.selectItemsClickCallbackRef = CustomSelect.getSelectItemsClickCallback;

        selectItemsElements.forEach(element => element.addEventListener('click', this.selectItemsClickCallbackRef));
    }

    private setInitialValues(): void {
        this.customSelectSelector = CustomSelectConstants.CUSTOM_SELECT_SELECTOR;
        this.customSelectDropdownSelector = CustomSelectConstants.CUSTOM_SELECT_DROPDOWN_SELECTOR;
    }
}
