import {ProxyPacketCardConfig} from '../../interfaces/proxy-packet-card-config.interface';

export class MainPageConstants {
    public static get PROXY_ACCOUNT_MODALS_SELECTORS(): string[][] {
        return [
            ['.modal-account-btn', '.modal-account-proxy-error'],
            ['.modal-account-btn', '.modal-account-proxy-wrong'],
            ['.modal-account-btn', '.modal-account-proxy-blocked'],
            ['.modal-account-btn', '.modal-account'],
            ['.modal-account-active-btn', '.modal-account-active'],
            ['.modal-account-expired-btn', '.modal-account-expired'],
        ];
    }

    public static get PROXY_BUY_MODALS_SELECTORS(): string[][] {
        return [
            ['.modal-buy-btn', '.modal-buy'],
        ];
    }

    public static get PRODUCT_CARDS_CONFIG(): { [key: string]: ProxyPacketCardConfig } {
        return {
            'ru': {
                ipCount: [1, 5, 10, 25, 50, 100, 200, 500],
                costKeys: [1, 5, 10, 25, 50, 100, 200, 500],
                costValues: [100, 200, 500, 1000, 2000, 3000, 5000, 7000],
                labelValues: ['IP-адрес', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов'],
                countryInputValue: 'RU',
                pricePostfix: '$ / МЕС'
            },
            'ua': {
                ipCount: [1, 5, 10, 25, 50, 100, 200, 500],
                costKeys: [1, 5, 10, 25, 50, 100, 200, 500],
                costValues: [101, 201, 501, 1001, 2001, 3001, 5001, 7001],
                labelValues: ['IP-адрес', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов'],
                countryInputValue: 'UA',
                pricePostfix: '$ / МЕС'
            },
            'eu': {
                ipCount: [1, 5, 10, 25, 50, 100, 150, 200],
                costKeys: [1, 5, 10, 50, 100, 150, 300, 500],
                costValues: [102, 202, 502, 1002, 2002, 3002, 5002, 7002],
                labelValues: ['IP-адрес', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов'],
                countryInputValue: 'EU',
                pricePostfix: '$ / МЕС'
            },
            'us': {
                ipCount: [1, 5, 10, 25, 50, 100, 150, 200],
                costKeys: [1, 5, 10, 50, 100, 150, 300, 500],
                costValues: [103, 203, 503, 1003, 2003, 3003, 5003, 7003],
                labelValues: ['IP-адрес', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов', 'IP-адресов'],
                countryInputValue: 'US',
                pricePostfix: '$ / МЕС'
            }
        };
    }

    public static get SWIPER_CONFIG(): any {
        return {
            slidesPerView: 2,
            // slidesPerGroup: 2,
            // spaceBetween: 15,
            loop: true,
            // slideToClickedSlide: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.comments-slider-pagination--dots',
                clickable: true,
                renderBullet: function (index: number, className: string): string {
                    return `<div class="comments-slider-pagination--dot ${className}"></div>`;
                },
            },
            // observer: true,
            // observeParents: true,
            // centeredSlides: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            // breakpoints: {
            //     960: {
            //         slidesPerView: 2,
            //         spaceBetween: 30
            //     },
            //     720: {
            //         slidesPerView: 1,
            //         spaceBetween: 6
            //     },
            //     540: {
            //         slidesPerView: 1,
            //         spaceBetween: 4
            //     },
            //     320: {
            //         slidesPerView: 1,
            //         spaceBetween: 2
            //     },
            // }
        };
    }

    public static get SWIPER_COUNTRY_CODES_MAPPING(): string[] {
        return ['ru', 'ua', 'eu', 'us'];
    }

    public static get DEFAULT_COUNTRY_CODE(): string {
        return 'ru';
    }

    public static get ANIMATION_DELAY_COEFFICIENT(): number {
        return 20;
    }

    public static get PRISE_CURRENCY_TEMPLATE(): string {
        return '<span class="total-cost-month-text__currency-symbol">р.</span>';
    }

    public static get COUNTRIES_MAPPING(): { [key: string]: string } {
        return {
            ru: 'Россия',
            ua: 'Украина',
            us: 'США',
            eu: 'Европа',
        };
    }

    public static get PROXY_PACKET_ACCOUNT_MODALS_SELECTORS(): string[][] {
        return [
            ['.modal-account-btn', '.modal-account-proxy-error'],
            ['.modal-account-btn', '.modal-account-proxy-wrong'],
            ['.modal-account-btn', '.modal-account-proxy-blocked'],
            ['.modal-account-btn', '.modal-account'],
            ['.modal-account-active-btn', '.modal-account-active'],
            ['.modal-account-expired-btn', '.modal-account-expired'],
        ];
    }
}
