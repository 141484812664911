import {AuthVpnResponse} from '../../interfaces/auth-vpn-response.interface';
import {VpnProductCardService} from '../vpn-product-card/vpn-product-card.service';
import {AppService} from '../../shared/app.service';
import {Helpers} from '../../shared/helpers';
import {ProxyPacketBaseService} from '../../views/proxy-packet-base/proxy-packet-base.service';

export class Account {
    public authFormContainerSelector: string = '.enter';
    public authUrl: string;
    public errorModal: string;

    constructor(public appService: AppService,
                public vpnProductCardService: VpnProductCardService,
                public proxyPacketService: ProxyPacketBaseService) {
        this.setInitialValues();
    }

    public init(): void {
        this.connectButtonClickSubscription();
    }

    public authRequest(codeValue: string, useMock: boolean = false, target?: HTMLElement): void {
        if (!codeValue) {
            return;
        }
        this.appService.authRequest(this.authUrl, codeValue, useMock)
            .then(response => {
                return response.text();
            })
            .then((response) => {
                const parsedResponse = Helpers.parseResponse(response);
                if (parsedResponse && parsedResponse.status === 'success') {
                    this.authSuccessHandler(parsedResponse, codeValue, target);
                } else {
                    this.authErrorHandler(parsedResponse, codeValue, target);
                }
            }).catch((err) => {
            this.bodyFix();
            document.querySelector(this.errorModal).classList.remove('modal-hidden');
        });
    }


    public authErrorHandler(response: any, code: string, target: HTMLElement): void {
    }

    public connectButtonClickSubscription(): void {

    }

    public backButtonClickSubscription(modalCurrent: Element): void {

    }

    public bodyFix(): void {
        document.body.classList.add('body-fixed');
    }

    public bodyUnfix(): void {
        document.body.classList.remove('body-fixed');
    }

    public connectButtonClickHandler(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        const target = event.target as HTMLElement;
        const codeValue = this.getAuthFormValue();
        this.authRequest(codeValue, false, target);
    }

    public getAuthFormValue(): string {
        const formContainer = document.querySelector(this.authFormContainerSelector);
        const inputElement = formContainer.querySelector('input') as HTMLInputElement;
        return inputElement.value;
    }

    public authSuccessHandler(response: any, codeValue?: string, target?: HTMLElement): void {
    }

    public openModal(modalSelector: string): void {
        const modal = document.querySelector(modalSelector);
        modal.classList.remove('modal-hidden');
    }

    public setExpirationDateValue(response: any, modal?: HTMLElement): void {
        const timeStampInSeconds = Math.round(Date.now() / 1000);
        const expireDateElement = modal.querySelector('#expireDate') as HTMLElement;
        if (!expireDateElement) {
            return;
        }
        if (response.expUnixTime < timeStampInSeconds) {
            expireDateElement.innerHTML = '<span class="span-red" id="expDate">Время действия аккаунта закончилось</span>';
        } else {
            expireDateElement.innerHTML = 'Статус подключения аккаунта: <span class="span-red input-text codeExpDate">' + response.expDate + '</span>';
        }
    }

    public setUserData(response: AuthVpnResponse): void {
        const codeHashElements = document.querySelectorAll('.codeHash');
        const codeEmailElements = document.querySelectorAll('.codeEmail');
        codeHashElements.forEach((codeHashElement) => codeHashElement.textContent = response.codeHash);
        codeEmailElements.forEach((codeEmailElement) => codeEmailElement.textContent = response.password);
    }

    public initModals(modalsToInit: string[][]): void {
        modalsToInit.forEach((item) => {
            this.initModal(item[0], item[1]);
        });
        this.renewPeriodChangeSubscription();
    }

    public initModal(btn: string, modal: string): void {
        const modalCurrent = document.querySelector(modal);
        this.preventDefaultModalButtonEvents(btn);
        this.closeButtonClickSubscription(modalCurrent);
        this.backButtonClickSubscription(modalCurrent);
        // this.clickOutsideSubscription(modalCurrent);
    }

    public clickOutsideSubscription(modalCurrent: Element): void {
        modalCurrent.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;
            if (target.closest('.modal__body') && !document.querySelector('#dlt-row')) {
                modalCurrent.classList.add('modal-hidden');
                console.log(event);
                this.bodyUnfix();
            }
        });
    }


    public closeButtonClickSubscription(modalCurrent: Element): void {
        const closeBtn = modalCurrent.querySelector('.btn-close');

        closeBtn.addEventListener('click', (event) => {
            modalCurrent.classList.add('modal-hidden');
            this.bodyUnfix();
        });
    }

    public preventDefaultModalButtonEvents(btn: string): void {
        const allButtons = document.querySelectorAll(btn);

        for (let i = 0; i < allButtons.length; i++) {
            allButtons[i].addEventListener('click', (event) => {
                event.preventDefault();
            });
        }
    }

    public showExtensionBlockSubscription(): void {
        document.querySelector('.show-extension-block').addEventListener('click', this.showExtensionBlockHandler.bind(this));
    }

    public showExtensionBlockHandler(event: Event): void {
    }

    public renewPeriodChangeSubscription(): void {

    }

    public renewPeriodChangeHandler(event: Event): void {

    }

    public updateRenewFormValues(): void {

    }

    public checkDiscount(): void {
    }


    public promocodeRenewButtonClickSubscription(): void {
        const button = document.querySelectorAll('#promo-code-renew-btn');
        button.forEach((element) => {
            element.addEventListener('click', this.promocodeRenewButtonClickHandler.bind(this));
        });
    }

    public promocodeRenewButtonClickHandler(event: Event): void {

    }

    public promocodeSuccessHandler(response: any, event: Event, promoCode: string): void {
        const totalCostInput = document.querySelector('#total-cost-renew') as HTMLInputElement;
        const promoCodeValueInput = document.querySelector('.promo-code-value') as HTMLInputElement;
        const promoCodeInput = document.querySelector('#renew-promo-code');
        const successBlock = document.querySelector('#renew-promo-text');
        const errorBlock = document.querySelector('#renew-promo-error');
        totalCostInput.setAttribute('data-percent', response.discount);
        totalCostInput.setAttribute('data-id', response.id);
        totalCostInput.setAttribute('data-start', response.start);
        promoCodeValueInput.value = promoCode;
        errorBlock.textContent = ``;
        successBlock.innerHTML = 'ВАША СКИДКА: <span class="sail__value"> ' + response.discount + '%</span>';
        promoCodeInput.setAttribute('disabled', 'disabled');
        this.updateRenewFormValues();

    }

    public promocodeErrorHandler(response: any, event: Event, promoCode: string): void {
        const parsedErrorMessage = this.vpnProductCardService.parsePromocodeErrors(response, promoCode);
        const promocodeErrorBlock = document.querySelector('#renew-promo-error');

        if (!!parsedErrorMessage) {
            promocodeErrorBlock.innerHTML = parsedErrorMessage;
        } else {
            this.resetPromocode();
        }
    }

    public resetPromocode(): void {
        const totalCostInput = document.querySelector('#total-cost-renew') as HTMLInputElement;
        const successBlock = document.querySelector('#renew-promo-text');
        const errorBlock = document.querySelector('#renew-promo-error');
        totalCostInput.setAttribute('data-percent', '0');
        totalCostInput.setAttribute('data-id', '0');
        totalCostInput.setAttribute('data-start', '0');
        totalCostInput.setAttribute('data-discount', '0');
        successBlock.innerHTML = 'ВАША СКИДКА: <span class="sail__value"> 0%</span>';
        errorBlock.innerHTML = '';
        this.updateRenewFormValues();
    }

    public openExtensionBlock(postfix: string = ''): void {
        const btn = document.querySelector(`.open-extension-block${postfix}`);
        btn.addEventListener('click', () => {
            this.resetExtensionBlock(postfix);
            this.updateRenewFormValues();
        });
    }

    public resetExtensionBlock(postfix: string = ''): void {
        const block = document.querySelector(`.extension-block${postfix}`);
        const btn = document.querySelector(`.open-extension-block${postfix}`);
        block.classList.toggle('padd-none');
        block.classList.toggle('open');
        block.classList.toggle('hidden');
        btn.classList.toggle('hidden');
    }

    public setInitialValues(): void {
    }
}

