export class AppService {
    public authRequest(url: string, code: string, useMock?: boolean): Promise<any> {
        if (useMock) {
            const mockedObject = {
                active: 1,
                cost: 100,
                countIps: 0,
                expDate: '2038-01-19',
                expUnixTime: 2147483647,
                status: 'success',
                type: 'HTTP',
            };
            return Promise.resolve({
                json: () => Promise.resolve(mockedObject),
                text: () => Promise.resolve(JSON.stringify(mockedObject)),
            });
        }
        return fetch(`${url}?code=${code}`);
    }
}
