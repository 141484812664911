import {AuthProxyPacketResponse} from '../../interfaces/auth-proxy-packet-response.interface';

export class ProxyPacketBaseService {
    public parseAuthResponseData(response: AuthProxyPacketResponse, code: string): any {
        const isNotActive = (response.expDate === '1970-01-01') || response.active === 0;
        const isExpired = response.active === 3;
        const isActive = (response.active === 1) || (response.active === 4);
        const isBlocked = response.status === 'blocked';
        const isWrong = !!code;
        switch (true) {
            case isNotActive:
                return {
                    modalSelector: '.modal-account-proxy-not-activate'
                };
            case isExpired:
                return {
                    modalSelector: '.modal-account-expired',
                    doSetInputValues: true
                };
            case isActive:
                return {
                    modalSelector: '.modal-account-active',
                    doSetInputValues: true
                };
            case isBlocked:
                return {
                    modalSelector: '.modal-account-proxy-blocked'
                };
            case isWrong:
                return {
                    modalSelector: '.modal-account-proxy-wrong'
                };
            default:
                return {
                    modalSelector: '.modal-account-proxy-error'
                };
        }
    }
}

