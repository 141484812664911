export class Jivoloader {
    private static jivoLoader(): void {
        Jivoloader.interactionUnSubscription();
        try {
            (function (): void {
                (function c(d: Document, w: Window, m: string, i?: any): void {
                    // @ts-ignore
                    window.supportAPIMethod = m;
                    const s = d.createElement('script');
                    s.id = 'supportScript';
                    const id = 'b0c257e24f4c87480a3677f4ea8520ff';
                    // tslint:disable-next-line:max-line-length
                    s.src = (!i ? 'https://lcab.talk-me.ru/support/support.js' : 'https://static.site-chat.me/support/support.int.js') + '?h=' + id;
                    s.onerror = i ? undefined : function (): void {
                        c(d, w, m, true);
                    };
                    w[m] = w[m] || function () {
                        (w[m].q = w[m].q || []).push(arguments);
                    };
                    (d.head || d.body).appendChild(s);
                })(document, window, 'TalkMe');
            })();
        } catch (e) {
            console.log(e);
        }

    }

    private static interactionSubscription(): void {
        window.addEventListener('mousemove', this.jivoLoader, true);
        window.addEventListener('scroll', this.jivoLoader, true);
        window.addEventListener('click', this.jivoLoader, true);
    }

    private static interactionUnSubscription(): void {
        window.removeEventListener('click', this.jivoLoader, true);
        window.removeEventListener('mousemove', this.jivoLoader, true);
        window.removeEventListener('scroll', this.jivoLoader, true);
    }

    public init(): void {
        Jivoloader.interactionSubscription();
    }
}
