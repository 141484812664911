import {Account} from '../../components/account/account';
import {MainPageConstants} from './main-page.constants';
import {AuthProxyResponse} from '../../interfaces/auth-proxy-response.interface';

export class MainPageAccount extends Account {
    public authUrl: string = '/ru/connect.php';
    public errorModal: string = '.modal-account-proxy-error';

    public init(): void {
        this.connectButtonClickSubscription();
        this.proxyTypeDropdownClickSubscription();
    }

    public connectButtonClickSubscription(): void {
        const buttonSelector = '#connect';
        const formSelector = '.enter__form';
        const button = document.querySelector(buttonSelector);
        const form = document.querySelector(formSelector);

        this.initModals(MainPageConstants.PROXY_ACCOUNT_MODALS_SELECTORS);
        this.initModals(MainPageConstants.PROXY_BUY_MODALS_SELECTORS);
        this.renewShowBtnSubscription();
        this.promocodeRenewButtonClickSubscription();
        this.showExtensionBlockSubscription();
        this.showSupportFormClickSubscription();

        button.addEventListener('click', this.connectButtonClickHandler.bind(this));
        form.addEventListener('submit', this.connectButtonClickHandler.bind(this));
    }

    public authSuccessHandler(response: AuthProxyResponse, codeValue: string, target: HTMLElement): void {
        const responseData = this.proxyPacketService.parseAuthResponseData(response, codeValue);
        const modal = document.querySelector(responseData.modalSelector) as HTMLElement;
        const successCodeContainer = document.querySelectorAll('.successCode');
        successCodeContainer.forEach((element: HTMLElement) => element.textContent = codeValue);
        this.openModal(responseData.modalSelector);
        if (responseData.doSetInputValues) {
            this.setTotalCostInputValue(response, codeValue, modal);
            this.setExpirationDateValue(response, modal);
        }
    }

    public setTotalCostInputValue(response: AuthProxyResponse, code: string, modal: HTMLElement): void {
        const totalCostRenewInput = modal.querySelector('#total-cost-renew');
        totalCostRenewInput.setAttribute('data-code', code);
        totalCostRenewInput.setAttribute('data-days', response.data);
        totalCostRenewInput.setAttribute('data-cost', response.cost.toString());
        totalCostRenewInput.setAttribute('data-count-ip', response.countIps.toString());
    }

    public authErrorHandler(response: AuthProxyResponse, codeValue: string): void {
        this.bodyFix();
        const responseData = this.proxyPacketService.parseAuthResponseData(response, codeValue);
        this.openModal(responseData.modalSelector);

    }

    public renewPeriodChangeSubscription(): void {
        const renewPeriodInput = document.querySelector('[name^="proxy-renew-period"]');
        renewPeriodInput.addEventListener('change', this.renewPeriodChangeHandler.bind(this));
    }

    public renewPeriodChangeHandler(event: Event): void {
        const target = event.target as HTMLInputElement;
        const totalCostRenewInput = document.querySelector('#total-cost-renew');
        totalCostRenewInput.setAttribute('data-mpr', target.value);
        // totalCostRenewInput.setAttribute('data-cost', target.getAttribute('data-cost'));
        this.updateRenewFormValues();
    }

    public updateRenewFormValues(): void {
        const totalCostRenewInputs = document.querySelectorAll('#total-cost-renew');
        const totalCostRenewTexts = document.querySelectorAll('#total-cost-renew-text');

        totalCostRenewInputs.forEach((totalCostRenewInput: HTMLInputElement, index) => {
            const monthsCount = parseInt(totalCostRenewInput.getAttribute('data-mpr'), 10);
            let price = parseInt(totalCostRenewInput.getAttribute('data-cost'), 10);
            const discount = parseInt(totalCostRenewInput.getAttribute('data-percent'), 10);

            price = price * monthsCount;

            if (discount > 1) {
                const percent = price / 100;
                price -= percent * discount;
            }
            price = parseFloat(price.toFixed(2));

            totalCostRenewTexts[index].textContent = price + ' $';
            totalCostRenewInput.value = price.toString();
        });
    }

    public promocodeRenewButtonClickHandler(event: Event): void {
        event.preventDefault();
        const modal = (event.target as HTMLElement).closest('.modal__body');
        const promoCode = (modal.querySelector('#renew-promo-code') as HTMLInputElement).value;
        if (!promoCode) {
            return;
        }
        const totalCostInput = modal.querySelector('#total-cost-renew') as HTMLInputElement;
        const countIp = totalCostInput.getAttribute('data-count-ip');
        const cost = totalCostInput.value;

        this.vpnProductCardService.checkPromocode('/ru/check.php', [['promo', promoCode], ['cost', cost], ['countIp', countIp], ['service', '1']])
            .then(response => response.json())
            .then((response) => {
                if (response && response.status === 'success') {
                    this.promocodeSuccessHandler(response, event, promoCode);
                } else {
                    this.promocodeErrorHandler(response, event, promoCode);
                }
            }).catch((err) => {
            console.log(`err ${err}`);
        });
    }

    public promocodeErrorHandler(response: any, event: Event, promoCode: string): void {
        const modal = (event.target as HTMLElement).closest('.modal__body');
        const parsedErrorMessage = this.vpnProductCardService.parsePromocodeErrors(response, promoCode);
        const promocodeErrorBlock = modal.querySelector('#renew-promo-text-error');

        if (!!parsedErrorMessage) {
            promocodeErrorBlock.innerHTML = parsedErrorMessage;
        } else {
            this.resetPromocode();
        }
    }

    public resetPromocode(): void {
        const totalCostInputs = document.querySelectorAll('#total-cost-renew');
        const successBlocks = document.querySelectorAll('#renew-promo-text');
        const errorBlocks = document.querySelectorAll('#renew-promo-text-error');
        totalCostInputs.forEach((element) => {
            element.setAttribute('data-percent', '0');
            element.setAttribute('data-id', '0');
            element.setAttribute('data-start', '0');
            element.setAttribute('data-discount', '0');
        });
        successBlocks.forEach((element) => {
            element.innerHTML = 'Ваша скидка: <span class="sail__value total__price--value"> 0%</span>';
        });
        errorBlocks.forEach((element) => {
            element.innerHTML = '';
        });
        this.updateRenewFormValues();
    }

    public showExtensionBlockSubscription(): void {
        this.openExtensionBlock('');
        this.openExtensionBlock('1');
    }

    public promocodeSuccessHandler(response: any, event: Event, promoCode: string): void {
        const modal = (event.target as HTMLElement).closest('.modal__body');
        const totalCostInput = modal.querySelector('#total-cost-renew') as HTMLInputElement;
        const promoCodeValueInput = modal.querySelector('#renew-promo-value') as HTMLInputElement;
        const promoCodeInput = modal.querySelector('#renew-promo-code');
        const successBlock = modal.querySelector('#renew-promo-text');
        const errorBlock = modal.querySelector('#renew-promo-text-error');
        totalCostInput.setAttribute('data-percent', response.discount);
        totalCostInput.setAttribute('data-id', response.id);
        totalCostInput.setAttribute('data-start', response.start);
        promoCodeValueInput.value = promoCode;
        errorBlock.textContent = ``;
        successBlock.innerHTML = 'Ваша скидка: <span class="sail__value total__price--value"> ' + response.discount + '%</span>';
        promoCodeInput.setAttribute('disabled', 'disabled');
        this.updateRenewFormValues();

    }

    private renewShowBtnSubscription(): void {
        const renewShowButton = document.querySelectorAll('.renew-show-btn');
        renewShowButton.forEach((element) => {
            element.addEventListener('click', this.renewShowBtnHandler.bind(this));
        });
    }

    private renewShowBtnHandler(event: Event): void {
        const modal = (event.target as HTMLElement).closest('.modal__body');
        const renewPromocodeInput = modal.querySelector('#renew-promo-code') as HTMLInputElement;
        const promoCodeRenewButton = modal.querySelector('#promo-code-renew-btn') as HTMLElement;
        renewPromocodeInput.value = '';
        renewPromocodeInput.removeAttribute('disabled');
        promoCodeRenewButton.click();
    }

    private showSupportFormClickSubscription(): void {
        const btn = document.querySelector(`.show-support-form--button`);
        btn.addEventListener('click', () => {
            this.resetExtensionBlock();
            this.resetSupportFormBlock();
        });
    }

    private resetSupportFormBlock(): void {
        const block = document.querySelector(`.extension-block__support`);
        block.classList.toggle('padd-none');
        block.classList.toggle('open');
        block.classList.toggle('hidden');
    }

    private proxyTypeDropdownClickSubscription(): void {
        const buttons = document.querySelectorAll('.download-label-wrapper');
        buttons.forEach((button) => {
            button.addEventListener('click', this.showProxyTypeDropdown.bind(this));
        });
    }

    private proxyTypeDropdownItemClickSubscription(dropdown: HTMLElement): void {
        const dropdownItems = dropdown.querySelectorAll('.download-label-dropdown__item');
        dropdownItems.forEach((element) => {
            element.addEventListener('click', this.changeSelectedProxyType.bind(this));
        });
    }

    private showProxyTypeDropdown(event: Event): void {
        const target = event.target as HTMLElement;
        let container = target.closest('.download-label-wrapper') as HTMLElement;
        container = !!container ? container : target;
        const dropdown = container.querySelector('.download-label-dropdown') as HTMLElement;
        dropdown.classList.add('download-label-dropdown--active');

        this.proxyTypeDropdownItemClickSubscription(dropdown);
    }

    private changeSelectedProxyType(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        const target = event.target as HTMLElement;
        const dropdown = target.closest('.download-label-dropdown') as HTMLElement;
        const wrapper = target.closest('.download-wrapper');
        const label = wrapper.querySelector('.download-label-description');
        const input = wrapper.querySelector('[name^="proxy-type"]') as HTMLInputElement;
        dropdown.classList.remove('download-label-dropdown--active');
        const value = target.getAttribute('data-val');
        input.value = value;
        if (value === '0') {
            label.textContent = 'HTTP';
        } else if (value === '1') {
            label.textContent = 'SOCKS';
        }
    }
}
