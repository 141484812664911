import * as MobileDetect from 'mobile-detect';

export class SelectChanger {
    public init(): void {
        this.initSelectChanger();
    }


    private initSelectChanger(): void {
        const userAgent = new MobileDetect(window.navigator.userAgent);
        const isMobile = userAgent.mobile() || userAgent.phone() || userAgent.tablet();
        const nodesToReplace = document.querySelectorAll('.custom-select');
        if (isMobile) {
            this.replaceCustomWithNative(nodesToReplace);
        }
    }

    private replaceCustomWithNative(nodesToReplace: NodeListOf<Element>): void {
        [].forEach.call(nodesToReplace, (node) => {
            const selectItems = node.querySelector('.select-items');
            if (!selectItems) {
                return;
            }
            const optionsNodes = node.querySelector('.select-items').children;
            const parsedOptionsValues = this.parseOptions(optionsNodes);
            const isCities = parsedOptionsValues[0].classList.contains('cities');
            const customSelectInputNode = node.querySelector('input');
            const inputAttributeValues = this.parseInputAttributesValue(customSelectInputNode);
            node = this.removeChildren(node);
            node.classList.add('native-select-wrapper');
            node.classList.remove('custom-select');
            const selectNode = this.createSelectNode(inputAttributeValues, parsedOptionsValues, isCities);
            node.appendChild(selectNode);
        });
    }

    private parseOptions(optionNodes: Element[]): any[] {
        const selectOptions = [];
        [].forEach.call(optionNodes, (optionNode) => {
            const optionDataObject = Object.create(null);
            optionDataObject['value'] = optionNode.getAttribute('data-value');
            optionDataObject['cost'] = optionNode.getAttribute('data-cost');
            optionDataObject['classList'] = optionNode.classList;
            optionDataObject['label'] = optionNode.textContent;
            selectOptions.push(optionDataObject);
        });
        return selectOptions;
    }

    private parseInputAttributesValue(inputNode: Element): { name: string;
        value: string } {
        const name = inputNode.getAttribute('name');
        const value = inputNode.getAttribute('value');
        const cost = inputNode.getAttribute('cost');
        return {
            name,
            value
        };
    }

    private removeChildren(node: Element): Element {
        node.querySelectorAll('*').forEach(n => n.remove());
        return node;
    }

    private createSelectNode(inputAttributeValues: object, parsedOptionsValues: string[], isCities: boolean): Element {
        const nativeSelect = document.createElement('select');
        nativeSelect.setAttribute('name', inputAttributeValues['name']);
        nativeSelect.setAttribute('value', inputAttributeValues['value']);
        nativeSelect.classList.add('native-select');
        if (isCities) {
            nativeSelect.setAttribute('cost', inputAttributeValues['cost']);
        }
        parsedOptionsValues.forEach((attributeObject) => {
            const option = document.createElement('option');
            option.textContent = attributeObject['label'];
            option.setAttribute('value', attributeObject['value']);
            if (isCities) {
                option.setAttribute('cost', attributeObject['cost']);
                option.classList.add('cities');
                option.classList.add(attributeObject['classList'][1]);
            }
            nativeSelect.appendChild(option);
        });
        return nativeSelect;
    }
}
