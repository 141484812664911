import {ProxyProductCardService} from './proxy-product-card.service';
import {MainPageConstants} from '../../views/main-page/main-page.constants';
import {ProxyPacketCardConfig} from '../../interfaces/proxy-packet-card-config.interface';

type SelectInput = HTMLInputElement | HTMLSelectElement;
declare let priceData: { [key: string]: any[] };

export class ProxyProductCard {
    private defaultCountry: string = 'Россия';
    private cardSelector: string = '.proxy-product-card';
    private countriesSelector: string = '[name^=\'countries\']';
    private citiesSelector: string = '[name^=\'cities\']';
    private periodSelector: string = '[name=\'period\']';
    private removeMoreButtonClickHandlerRef: any;
    private countryChangedHandlerRef: any;
    private periodChangedHandlerRef: any;
    private countChangedHandlerRef: any;
    private rangeSliderChangedHandlerRef: (this: GlobalEventHandlers, ev: Event) => any;
    private swiperConfig: any;
    private swiperSelector: string;
    private priceCurrencyTemplate: string;
    private swiperCountryCodeMapping: string[];
    private productCardsConfig: { [p: string]: ProxyPacketCardConfig };
    private animationDelayCoefficient: number;
    private selectedCountry: string;

    constructor(private proxyProductCardService: ProxyProductCardService) {
    }

    // public static countryChanged(countryCode: string): void {
    //     document.querySelector('.tarifs__active--country').textContent = MainPageConstants.COUNTRIES_MAPPING[countryCode];
    // }

    public init(): void {
        this.buyButtonCardClickSubscription();
        this.countryChangedSubscription();
        this.buyPeriodChangeSubscription();
        this.setInitialValues();
    }

    public openModal(modalSelector: string): void {
        const modal = document.querySelector(modalSelector);
        modal.classList.remove('modal-hidden');
    }

    public buyPeriodChangeSubscription(): void {
        const buyPeriodInput = document.querySelector('[name^="period"]');
        buyPeriodInput.addEventListener('change', this.buyPeriodChangeHandler.bind(this));
    }

    public buyPeriodChangeHandler(event: Event): void {
        const target = event.target as HTMLInputElement;
        const modal: HTMLDivElement = (event.target as HTMLDivElement).closest('.modal__body');
        const count = parseInt(modal.querySelector('.tarifs__item__top-count').textContent, 10);
        const totalCostBuyInput = document.querySelector('#total-cost-buy');
        totalCostBuyInput.setAttribute('data-mpr', target.value);
        // totalCostBuyInput.setAttribute('data-cost', target.getAttribute('data-cost'));
        this.updateBuyFormValues(count);
    }

    public updateBuyFormValues(count: number): void {
        const totalCostBuyInput = document.querySelector('#total-cost-buy') as HTMLInputElement;
        const countIpInput = document.querySelector('#buy-countIp-value') as HTMLInputElement;
        const countryInput = document.querySelector('#buy-country-value') as HTMLInputElement;
        const priceZoneInput = document.querySelector('#buy-price-zone-value') as HTMLInputElement;
        const totalCostBuyText = document.querySelector('#total-cost-buy-text');
        const tarifsBlock = document.querySelector('.tarifs') as HTMLElement;
        const realCountryName = (tarifsBlock as HTMLElement).getAttribute('data-country');

        countIpInput.value = count.toString();
        countryInput.value = realCountryName ? realCountryName : this.selectedCountry.toUpperCase();
        priceZoneInput.value = this.selectedCountry.toUpperCase();

        const monthsCount = parseInt(totalCostBuyInput.getAttribute('data-mpr'), 10);
        let price = parseInt(totalCostBuyInput.getAttribute('data-cost'), 10);
        const discount = parseInt(totalCostBuyInput.getAttribute('data-percent'), 10);

        price = price * monthsCount;

        if (discount > 1) {
            const percent = price / 100;
            price -= percent * discount;
        }
        price = parseFloat(price.toFixed(2));

        totalCostBuyText.textContent = price + ' $';
        totalCostBuyInput.value = price.toString();

    }

    private buyButtonCardClickSubscription(): void {
        const buttons = document.querySelectorAll('.card-buy-button');
        buttons.forEach((button: HTMLInputElement) => {
            button.addEventListener('click', this.buyButtonCardClickHandler.bind(this));
        });
    }

    private buyButtonCardClickHandler(event: Event): void {
        const card: HTMLDivElement = (event.target as HTMLDivElement).closest('.tarifs__item');
        this.setModalValues(card);
        this.openModal('.modal-buy');
    }

    private setInitialValues(): void {
        this.swiperConfig = MainPageConstants.SWIPER_CONFIG;
        this.swiperSelector = '.swiper-container';
        this.priceCurrencyTemplate = MainPageConstants.PRISE_CURRENCY_TEMPLATE;
        this.swiperCountryCodeMapping = MainPageConstants.SWIPER_COUNTRY_CODES_MAPPING;
        this.getProductData();
        this.animationDelayCoefficient = MainPageConstants.ANIMATION_DELAY_COEFFICIENT;

        const countryWrapper = document.querySelector('.tarifs__countries-wrapper');
        const tarifsBlock = document.querySelector('.tarifs') as HTMLElement;

        if (countryWrapper) {
            this.selectedCountry = MainPageConstants.DEFAULT_COUNTRY_CODE;
        } else if (tarifsBlock) {
            this.selectedCountry = (tarifsBlock as HTMLElement).getAttribute('data-price-zone') || MainPageConstants.DEFAULT_COUNTRY_CODE;
        } else {
            this.selectedCountry = MainPageConstants.DEFAULT_COUNTRY_CODE;
        }

        this.updateCardsValues();
    }

    private setModalValues(card: HTMLDivElement): void {
        const count = parseInt(card.querySelector('.tarifs__item__top-count').textContent, 10);
        const indexOfCardConfig = this.productCardsConfig[this.selectedCountry].ipCount.indexOf(count);
        const modal = document.querySelector('.modal-buy');
        const totalCostBuyInput = document.querySelector('#total-cost-buy') as HTMLInputElement;
        modal.querySelector('.tarifs__item__top-count').innerHTML =
            `<span>${this.productCardsConfig[this.selectedCountry].ipCount[indexOfCardConfig]} ${this.productCardsConfig[this.selectedCountry].labelValues[indexOfCardConfig]}</span>`;
        modal.querySelector('.tarifs__item__top-price').innerHTML =
            ` <span class="tarifs__item__top-price-gradient text-gradient">${this.productCardsConfig[this.selectedCountry].costValues[indexOfCardConfig]} $</span>/ мес`;
        totalCostBuyInput.setAttribute('data-cost', this.productCardsConfig[this.selectedCountry].costValues[indexOfCardConfig].toString());

        modal.querySelector('#promo-code-buy-btn').addEventListener('click', (event) => {
            this.promocodeClickHandler(event);
        });
        this.updateBuyFormValues(count);
    }

    private countryChangedSubscription(): void {
        const countryItems = document.querySelectorAll('.tarifs__countries-item');
        countryItems.forEach((item) => {
            item.addEventListener('click', this.countryChangedHandler.bind(this));
        });
    }

    private countryChangedHandler(event: Event): void {
        const priceZone = (event.target as HTMLDivElement).getAttribute('data-country');
        const closestCountryItem = (event.target as HTMLDivElement).closest('.tarifs__countries-item');
        const countryItemsWrapper = (event.target as HTMLDivElement).closest('.tarifs__countries-items');
        const countryItems = countryItemsWrapper.querySelectorAll('.tarifs__countries-item');

        this.selectedCountry = priceZone;

        countryItems.forEach(item => item.classList.remove('tarifs__countries-item--active'));

        closestCountryItem.classList.add('tarifs__countries-item--active');

        this.updateCardsValues();
    }

    private updateCardsValues(): void {
        const tariffsItems = document.querySelectorAll('.tarifs__item');
        const config = this.productCardsConfig[this.selectedCountry];
        tariffsItems.forEach((item, index) => {
            item.querySelector('.tarifs__item__top-price-gradient').innerHTML = `<span itemprop="price">${config.costValues[index]}</span> $`;
            item.querySelector('.tarifs__item__top-count').innerHTML = `<span>${config.ipCount[index]} ${config.labelValues[index]}</span><div class="tarifs__item--hit--label">ХИТ!</div>`;
        });
    }

    private promocodeClickHandler(event: Event): void {
        const modal = (event.target as HTMLElement).closest('.modal__body');
        const countIp = parseInt(modal.querySelector('.tarifs__item__top-count').textContent, 10);
        const totalCostBuyInput = document.querySelector('#total-cost-buy') as HTMLInputElement;
        const cost = totalCostBuyInput.value;
        const promo = (document.querySelector('#buy-promo-code') as HTMLInputElement).value;
        if (!promo) {
            return;
        }
        const rawRequestParams = [
            ['promo', promo],
            ['cost', cost],
            ['countIp', countIp.toString()],
            ['service', '1']
        ];
        this.proxyProductCardService.checkPromocode(rawRequestParams)
            .then(response => response.json())
            .then((response) => {
                if (response && response.status === 'success') {
                    this.promocodeSuccessHandler(response, promo, countIp);
                } else {
                    // this.promocodeErrorHandler(response, event, promocodeValue);
                }
            }).catch((err) => {
            console.log(`err ${err}`);
        });

    }

    private promocodeSuccessHandler({discount}: { discount: number }, promocode: string, countIp: number): void {
        const totalCostBuyInput = document.querySelector('#total-cost-buy') as HTMLInputElement;
        const discountValueElement = document.querySelector('#buy-promo-text').querySelector('.sail__value') as HTMLElement;
        const discountValueInput = document.querySelector('#buy-promo-value') as HTMLInputElement;
        discountValueInput.value = promocode;
        discountValueElement.textContent = `${discount}%`;
        totalCostBuyInput.setAttribute('data-percent', discount.toString());
        this.updateBuyFormValues(countIp);
    }

    private getProductData(): void {
        this.productCardsConfig = MainPageConstants.PRODUCT_CARDS_CONFIG;
        Object.keys(this.productCardsConfig).forEach((country) => {
            this.productCardsConfig[country].costValues = Object.values(priceData[country.toUpperCase()]);
        });
    }
}
