import {ProxyPriceParams} from '../../interfaces/proxy-price-params.interface';

export class ProxyProductCardService {
    public parseFromData(params: ProxyPriceParams): { cost: number, totalIpCount: number } {
        let cost = 0;
        let totalIpCount = 0;
        params.selectedCityPrice.forEach((value: number, index: number) => {
            if (value) {
                cost += value * (params.selectedCount[index] || 0) * params.periodMonthsCount;
                totalIpCount += params.selectedCount[index] || 0;
            }
        });


        if (params.discount > 1) {
            const percent = cost / 100;
            cost -= percent * params.discount;
        }
        return {cost, totalIpCount};
    }

    public checkPromocode(rawRequestParams: string[][]): Promise<any> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return fetch('/ru/check.php?' + new URLSearchParams(rawRequestParams).toString(), {headers});

    }

    public parsePromocodeErrors(response: any, promocode: string): string {
        if (!response) {
            return 'Вы ввели неверный промокод!';
        }
        switch (true) {
            case response.status === 'noStart':
                return 'Промокод не действителен!';
            case response.status === 'expired':
                return 'Время действия промокода истекло!';
            case response.status === 'countError':
                return `Промокод не действителен <br> для заказанного кол-ва!`;
            case !!promocode:
                return 'Вы ввели неверный промокод!';
            default:
                return null;
        }
    }
}
