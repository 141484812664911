import {AuthVpnResponse} from '../interfaces/auth-vpn-response.interface';

export class Helpers {
    public static parseNumberAttributeValue(node: Element, attribute: string): number {
        const mprAttributeValue = node.getAttribute(attribute);
        return parseInt(mprAttributeValue, 10);
    }

    public static getNodeListArray<T>(parent: Element, selector: string): T[] {
        const nodeList = parent.querySelectorAll(selector);
        return Array.prototype.slice.call(nodeList);
    }

    public static removeListener(elementNodeListOf: NodeListOf<Element> | Element[], handler: any, event: string): void {
        if (!handler || !elementNodeListOf || !elementNodeListOf.length) {
            return;
        }
        elementNodeListOf.forEach(element => element.removeEventListener(event, handler));
    }

    public static parseResponse(response: any): AuthVpnResponse {
        if (!response) {
            return Object.create(null);
        }

        const startIndex = response.indexOf('{');
        const endIndex = response.indexOf('}') + 1;
        const responseString = response.substring(startIndex, endIndex);
        return JSON.parse(responseString);
    }

    public static removeOptions(selectElement: HTMLSelectElement): void {
        const optionsCount = selectElement?.options?.length;
        if (!optionsCount) {
            return;
        }
        for (let i = optionsCount - 1; i >= 0; i--) {
            selectElement.remove(i);
        }
    }

}
