export class CustomSelectStatic {
    protected static get getSelectItemsClickCallback(): (event: Event) => void {
        return function (event: Event): void {
            let selectedValueElement = this.closest('div').querySelector('.same-as-selected');

            CustomSelectStatic.removeIdentifierFromPrevSelectedItems(selectedValueElement);
            CustomSelectStatic.setIdentifierToSelectedItem(event);

            selectedValueElement = this.closest('div').querySelector('.same-as-selected');

            CustomSelectStatic.setAttributesToInput(selectedValueElement);
            CustomSelectStatic.setValueToInput(this, event);

            CustomSelectStatic.setSelectedItemValue(this, event);

            CustomSelectStatic.dispatchChangeEvent(this);

            CustomSelectStatic.closeAllSelects(null);
        };
    }

    protected static get getSelectClickCallback(): (event: Event) => void {
        return function (event: Event): void {
            event.stopPropagation();
            CustomSelectStatic.closeAllSelects(this);
            this.nextElementSibling.classList.toggle('select-hide');
            this.classList.toggle('select-arrow-active');
        };
    }

    protected static removeIdentifierFromPrevSelectedItems(sameAsSelectedElement: HTMLElement): void {
        if (sameAsSelectedElement) {
            sameAsSelectedElement.classList.remove('same-as-selected');
        }
    }

    protected static setIdentifierToSelectedItem(event: Event): void {
        const targetElement = (event.target as HTMLElement);
        const isImg = this.checkIsImage(targetElement);

        isImg ? targetElement.parentElement.classList.add('same-as-selected') : targetElement.classList.add('same-as-selected');
    }

    protected static setAttributesToInput(sameAsSelectedElement: HTMLElement): void {
        if (!sameAsSelectedElement || !sameAsSelectedElement.classList.contains('cities')) {
            return;
        }
        const input = sameAsSelectedElement.parentElement.parentElement.querySelector('input');
        const attributes = sameAsSelectedElement.attributes;
        for (let i = attributes.length - 1; i >= 0; i--) {
            const name = attributes[i].name === 'data-value' ? attributes[i].name.replace('data-', '') : attributes[i].name;
            const value = attributes[i].value.replace('data-', '');
            input.setAttribute(name, value);
        }

    }

    protected static setValueToInput(self: any, event: Event): HTMLInputElement {
        const htmlInputElement = self.parentElement.querySelector('input');
        htmlInputElement.value = CustomSelectStatic.getValue(event);
        return htmlInputElement;
    }

    protected static setSelectedItemValue(self: any, event: Event): void {
        self.parentElement.querySelector('.select-selected').innerHTML =
            CustomSelectStatic.getTextContent(event);
    }

    protected static dispatchChangeEvent(self: any): void {
        const htmlInputElement = self.parentElement.querySelector('input');
        const inputEvent = new Event('change', {
            bubbles: true,
            cancelable: true,
        });

        htmlInputElement.dispatchEvent(inputEvent);
    }

    protected static closeAllSelects(clickedSelect: Element): void {
        const selects = document.querySelectorAll('.select-selected');

        selects.forEach((element) => {
            if (clickedSelect === element) {
                return;
            }
            element.classList.remove('select-arrow-active');
            element.parentElement.querySelector('.select-items').classList.add('select-hide');
        });
    }

    protected static getTextContent(event: Event): string {
        const targetElement = (event.target as HTMLElement);
        const isImg = this.checkIsImage(targetElement);

        return isImg ? targetElement.parentElement.innerHTML : targetElement.innerHTML;
    }

    protected static checkIsImage(element: Element): boolean {
        const nodeName = element.nodeName;
        return nodeName === 'IMG';
    }

    protected static getValue(event: Event): string {
        const targetElement = (event.target as HTMLElement);
        const isImg = this.checkIsImage(targetElement);

        return isImg ? targetElement.parentElement.getAttribute('data-value') : targetElement.getAttribute('data-value');
    }
}
