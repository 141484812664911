export class NavComponent {
    public inited: boolean;
    private langMenu: Element;
    private header: Element;
    private informer: Element;

    private static menuItemClickHandler(eventElement?: any, allMenuElements?: NodeListOf<Element>, langMenu?: Element): void {
        if (!!allMenuElements && !!allMenuElements.length) {
            allMenuElements.forEach(element => {
                element.classList.remove('menu__item-active', 'dropdown_active');
            });
        }
        if (!!langMenu) {
            langMenu.classList.remove('menu__item-active', 'dropdown_active');
        }
        if (!!eventElement) {
            eventElement.classList.toggle('menu__item-active');
            eventElement.classList.toggle('dropdown_active');
            this.clickOutSubscription(allMenuElements);
        }
    }

    private static clickOutSubscription(elements: NodeListOf<Element>): void {
        const clickOutListener = (event: MouseEvent) => {
            const isMenuItem = (event.target as HTMLElement).closest('.menu__item__dropdown');
            if (!isMenuItem) {
                this.clickOutUnsub(clickOutListener);
                this.menuItemClickHandler(null, elements);
            }
        };
        document.addEventListener('click', clickOutListener);
    }

    private static clickOutUnsub(clickOutListener: any): void {
        document.removeEventListener('click', clickOutListener);
    }

    public init(): void {
        this.setInitialValues();
        this.toggleNavbarSize();
        this.windowScrollEventSubscription();
        this.burgerButtonClickSubscription();
        this.menuItemClickSubscription();
        this.dropdownMenuClickSubscription();
        this.inited = true;
    }

    private windowScrollEventSubscription(): void {
        window.addEventListener('scroll', () => {
            this.toggleNavbarSize();
        });
    }

    private toggleNavbarSize(): void {
        if (window.pageYOffset > 0) {
            this.header.classList.add('header-onscroll');
        } else {
            this.header.classList.remove('header-onscroll');
        }
    }

    private burgerButtonClickSubscription(): void {
        const burger = document.querySelector('.burger');

        burger.addEventListener('click', () => {
            this.burgerButtonClickHandler(burger);
        });
    }

    private burgerButtonClickHandler(element: Element): void {
        const main = document.querySelector('.main');
        const body = document.querySelector('body');

        element.classList.toggle('burger-active');
        this.header.classList.toggle('header-menu-active');
        main.classList.toggle('opened');
        body.classList.toggle('hdn');
    }

    private menuItemClickSubscription(): void {
        const elements = document.querySelectorAll('.menu__item-clicked');
        const langMenu = this.langMenu;

        elements.forEach((element) => {
            element.addEventListener('click', function (): void {
                NavComponent.menuItemClickHandler(this, elements, langMenu);
            });
        });
    }

    private dropdownMenuClickSubscription(): void {
        const dropdownItems = document.querySelectorAll('.menu__item__dropdown');
    
        dropdownItems.forEach((dropdownItem) => {
            const link = dropdownItem.querySelector('a');
    
            dropdownItem.addEventListener('click', (event: MouseEvent) => {
                if (event.target === link) {
                    event.preventDefault();
                    const isActive = dropdownItem.classList.contains('menu__item-active') && dropdownItem.classList.contains('dropdown_active');
                    this.closeAllDropdowns(dropdownItems);
    
                    if (!isActive) {
                        dropdownItem.classList.add('menu__item-active', 'dropdown_active');
                        NavComponent.clickOutSubscription(dropdownItems);
                    }
                } else {
                    return;
                }
            });
        });
    }

    private closeAllDropdowns(dropdownItems: NodeListOf<Element>): void {
        dropdownItems.forEach((item) => {
            item.classList.remove('menu__item-active', 'dropdown_active');
        });
    }

    private setInitialValues(): void {
        this.langMenu = document.querySelector('.lang-menu');
        this.header = document.querySelector('header');
        this.informer = document.querySelector('.informer');
    }
}